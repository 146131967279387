// IMPORTS
@import (once) "/node_modules/nouislider/dist/nouislider.css";
@import (once) "/node_modules/@fancyapps/ui/dist/fancybox/fancybox.css";
@import (once) "/node_modules/basiclightbox/dist/basicLightbox.min.css";
@import (once) "/node_modules/tippy.js/dist/tippy.css";
@import (once) "/node_modules/tippy.js/animations/shift-toward.css";

@import (reference) "settings.less";
@import (once) "forms.less";

.fancybox__container,
.basicLightbox {
    z-index: 100000;
}

.fancybox__container .has-iframe .fancybox__content {
    padding: 0;
    height: 90% !important;
}

.basicLightbox {
    background-color: rgba(0,0,0,.5);
}

.listing-wrapper .infobox {
    width: 100%;
}

// SHOWCASE
@keyframes pointAnimation {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    50% {
        transform: scale(1.4w);
        opacity: .75;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

[data-showcase] {
    clear: both;
    width: 100%;

    figure {
        position: relative;
        width: 100%;
    }

    img {
        display: block;
        margin: 0 auto;
        width: 100% !important;
    }

    em {
        display: block;
        text-align: center;
        font-style: normal;
        margin-top: 10px;
    }
}

.showcase-canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.showcase-point {
    transition: border-color 250ms ease-in-out,opacity 250ms ease-in-out,visibility 250ms ease-in-out;
    transform: translateX(-1rem) translateY(-1rem) scale(0.999);
    padding: 0;
    width: 2rem;
    height: 2rem;
    border: 2px solid transparent;
    position: absolute;
    background: rgba(0,0,0,.2);
    border-radius: 64px;
    line-height: .5;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
        border-color: rgba(255,255,255,.5);
        background: rgba(0,0,0,.4);
    }

    &:before {
        content: '';
        position: absolute;
        top: -0.5rem;
        left: -0.5rem;
        right: -0.5rem;
        bottom: -0.5rem;
        display: block;
    }

    &:after {
        content: '';
        transition: transform 250ms ease-in-out;
        position: relative;
        display: block;
        background: #fff;
        box-shadow: 0 1px 4px rgba(0,0,0,.55);
        width: .75rem;
        height: .75rem;
        border-radius: 64px;
        animation: pointAnimation 2s ease-in-out infinite both;
    }
}

.tippy-box {
    background-color: white;
    color: @font-color;
    box-shadow: 0 1px 4px rgba(0,0,0,.55);
    border-radius: 0;

    .tippy-content {
        padding: 10px;
    }

    .tippy-arrow {
        color: white;

        &:before {
            filter: drop-shadow(0 2px 1px rgba(0,0,0,.32));
        }
    }
}

.tooltip-content {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;

    > span {
        color: @header-color;
        font-weight: bold;
        font-size: 14px;
    }

    > p {
        font-size: 13px;
        margin: 0;
    }

    > a {
        color: @sec-color;
        border-radius: 5px;
        width: fit-content;
        text-decoration: underline;
        font-weight: bold;

        &:hover {
            opacity: .75;
        }
    }
}

[data-tippy-root] {
    z-index: 5 !important;
}

// NEWSLETTER
#newsletter {
    position: relative;
    color: white;
    height: 290px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &.unchecked .newsletter-clauses-wrapper:after {
        content: 'Zaznaczenie zgody jest konieczne, aby zapisać się do Newslettera';
        float: left;
        clear: both;
        color: @error-color;
        margin-top: 10px;
    }
}

.newsletter-bg {
    .center-lr();
    top: 0;
    width: 101vw;
    height: 100%;
    z-index: -1;
    background-color: @main-color;
    background-position: center center;
}

.newsletter-header {
    text-align: center;

    b {
        font-size: 28px;
        display: block;
    }

    p {
        margin-top: 4px;
    }
}

.newsletter-input {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 18px;
    width: 774px;

    input[type="text"] {
        width: 100%;
        height: 52px;
        background-color: transparent;
        border: 2px solid white;
        text-align: center;
        color: white;
        font-size: 16px;
        font-family: @main-font-family;

        &:focus::placeholder {
            color: transparent;
        }

        &::-webkit-input-placeholder {
            color: white;
        }
        &::-moz-placeholder {
            color: white;
        }
        &:-ms-input-placeholder {
            color: white;
        }
        &:-moz-placeholder {
            color: white;
        }
    }

    .popup-trigger {
        margin-top: 7px;
        background-color: white;
        color: @header-color;
        text-transform: uppercase;
        font-weight: 700;
        width: 130px;
        line-height: 3.2;
        text-align: center;
        cursor: pointer;
        border-radius: var(--borderRadius);

        &:hover {
            background-color: @header-color;
            color: white;
        }
    }
}

.newsletter-clauses-wrapper.unchecked:after {
    content: 'Zaznaczenie zgody jest konieczne, aby zapisać się do Newslettera';
    clear: both;
    color: @error-color;
    margin-top: 10px;
}

// PRODUCERS BANNER
.producers-banner {
    position: relative;
    padding-top: 40px;

    &:before {
        .hr-after();
        top: 0;
    }

    .swiper-slide {
        width: 180px;
        height: 90px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: filter .2s ease-in-out;
    }

    img {
        max-width: 100%;
        width: auto;
        height: auto;
    }

    .producer-button {
        margin-top: 0;
    }
}

@media (min-width: 1280px) {
    .producers-banner {

        .swiper-slide {
            width: ~"calc((100% - (6 * 30px)) / 7)";
            margin-right: 30px;

            &:hover {
                filter: grayscale(1);
            }
        }
    }
}

// FOOTER
#footer {
    position: relative;

    &:after {
        content: '';
        .center-lr();
        top: 0;
        width: 101vw;
        height: 100%;
        z-index: -2;
        background-color: @sec-color;
    }

    .map-wrapper {
        max-width: none;
        width: 520px;
        aspect-ratio: ~"520/236";
        flex-shrink: 0;
        margin-left: 75px;

        .map {
            .img-crop();
            border-radius: 8px;
        }
    }
}

.footer-wrapper {
    border-bottom: 1px solid rgba(223,223,223,.2);
    padding: var(--rowGap) 0;
    display: flex;
    justify-content: space-between;
}

.footer-content {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    flex: 1;
}

.footer-item {
    color: white;
    max-width: 260px;
    overflow: hidden;

    > strong {
        font-weight: 700;
        margin-bottom: 16px;
        text-transform: uppercase;
        display: block;
    }
}

.footer-item.submenu {

    > strong {
        margin-bottom: 8px;
    }

    a {
        display: inline-block;
        padding: 8px 0;
        color: @footer-gray;
        font-size: 14px;

        &:hover {
            text-decoration: underline;
        }
    }
}

.footer-item.location {

    .location-text {
        display: flex;
        flex-direction: column;
        gap: 18px;

        > div {
            display: flex;
            flex-direction: column;
            gap: 2px;
            padding-left: 34px;
            position: relative;
            color: @footer-gray;

            &:before {
                .before();
                position: absolute;
                left: 0;
                top: 0;
                color: white;
            }

            b {
                display: flex;
                align-items: center;
            }
        }

        .address:before {
            content: "\e919";
        }

        .hours:before {
            content: "\e918";
        }
    }
}

.footer-item.contact {

    > i {
        font-style: normal;
        font-weight: 700;
        color: @footer-gray;
        display: block;
        margin-bottom: 8px;
    }

    a {
        display: flex;
        gap: 8px;
        align-items: center;
        padding: 8px 0;
        color: @footer-gray;

        &:hover {
            color: white;
        }

        &:before {
            .before();
            color: white;
            font-size: 20px;
        }
    }

    .email:before {
        content: "\e912";
    }

    .phone:before {
        content: "\e913";
    }
}

.footer-bottom-bar {
    display: flex;
    justify-content: space-between;
    padding: 30px 0;
    font-size: 14px;

    a {
        color: @footer-gray;

        &:hover {
            color: white;
        }
    }

    > span {
        color: @footer-gray;
    }
}

// FACEBOOK SIDE
#facebook-box {
    position: fixed;
    width: 320px;
    top: 268px;
    right: -320px;
    z-index: 999;
    transition: transform .3s ease;

    &.show {
        transform: translateX(-320px);

        #in-facebook-box {
            box-shadow: 0 0 30px rgba(0,0,0,.3);
        }
    }

    #fb-open {
        display: block;
        position: absolute;
        left: -45px;
        top: 17px;
        width: 45px;
        cursor: pointer;
        transform-origin: right center;
        font-style: normal;
        background-color: white;
        border-radius: 8px;

        &:before {
            content: '\e806';
            font-family: icomoon;
            color: #4a6ea8;
            font-size: 45px;
            line-height: 45px;
            width: 45px;
            height: 45px;
            text-align: center;
            display: block;
            z-index: 1;
        }

        &:hover {
            left: -42px;
            transform: scale(1.1);
        }
    }

    img {
        max-width: 100%;
    }
}

#in-facebook-box {
    width: 320px;
    z-index: 999;
    cursor: default;
    background-color: #fff;
}

// MODAL STYLES
.dpd-pickup-modal {
    width: 50vw !important;
    max-height: 90dvh !important;
    overflow: auto;

    @media (max-width: 1100px) {
        width: 90vw !important;
    }
}

.goshop-modal {
    background-color: white;
    .shadow();
    max-height: 90vh;
    max-width: 90vw;
    overflow-y: auto;
    position: relative;
    width: 700px;
    border-radius: 5px;
    padding: 30px;

    .headline {
        font-size: 20px;
        margin-bottom: 20px;
    }

    &.goshop-modal-form {
        width: 50vw !important;
    }

    &.question-response {
        text-align: center;
        font-size: 18px;
        color: @header-color;
    }

    label {
        user-select: none;
        cursor: pointer;
        margin-bottom: 25px;
        display: block;
        padding-left: 30px;
        position: relative;

        input[type="checkbox"] {
            display: none;

            &:checked + span:before {
                content: '\e816';
            }
        }

        span {
            font-size: 18px;
            position: absolute;
            top: 0;
            .line-height();
            left: 0;

            &:before {
                content: '\f096';
                font-family: icomoon;
                color: @header-color;
            }
        }

        a {
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }
}

.goshop-modal header {
    background-color: @light-bg;
    height: 60px;
    color: @header-color;
    display: flex;
    align-items: center;
    font-size: 21px;
    border-bottom: 1px solid @border-color;

    &:before {
        content: '\e900';
        font-family: icomoon;
        font-weight: normal;
        width: 60px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 22px;
    }

    & ~ .goshop-modal-close {
        width: 60px;
        height: 60px;
        font-size: 18px;
        top: 0;
        right: 0;
    }
}

.goshop-modal-close {
    color: @header-color;
    cursor: pointer;
    font-style: normal;
    height: 30px;
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;

    &:after {
        content: '\e803';
        font-family: icomoon;
    }
}

.cart-error-modal .goshop-modal {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: @header-color;
    font-size: 18px;

    &:before {
        content: '\e900';
        font-family: icomoon;
        color: @error-color;
        width: 80px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 70px;
        margin-bottom: 20px;
    }
}

.modal-form-content {

    .field-wrapper:not(.checking-reg):after {
        display: none !important;
    }

    .field-wrapper:not(.checking-reg).filled input[type="text"], .field-wrapper:not(.checking-reg).filled input[type="password"], .field-wrapper:not(.checking-reg).filled input[type="email"] {
        padding-right: 23px;
    }

    .field-wrapper:not(.checking-reg) .form-hint {
        position: absolute;
        top: 11px;
        right: 23px;
        z-index: 2;
    }
}

.captcha-wrapper {
    margin: 15px 0;
    clear: both;
}

.question-form-text {
    margin-bottom: 15px;
}

// CART SUCCESS MODAL
.cart-success {

    .goshop-modal {
        padding: 0;
    }

    header {

        &:before {
            content: '\e801';
            font-size: 30px;
            color: @success-color;
        }
    }

    .popup-validation-error {
        color: @error-color;
        margin-bottom: 10px;
        width: 100%;
    }

    .cart-popup-row-wrapper {
        display: flex;

        > div > strong {
            color: @header-color;
            font-weight: normal;
        }
    }

    section {
        padding: 20px;

        figure {
            width: @custom-width;
            height: @custom-height;
            flex-shrink: 0;
            margin-right: 15px;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                max-width: 100%;
                max-height: 100%;
            }
        }

        > div:not(.cart-popup-row-wrapper) {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        ul {
            text-transform: lowercase;
            margin-top: 5px;

            span {

            }

            strong {
                color: @header-color;
                font-weight: normal;
            }
        }
    }

    footer {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0 20px 20px;
    }
}

// INFOBOX
.new-password-form {
    width: 50%;
    margin-top: 20px;

    .form-hint {
        text-align: left;
    }

    .confirm-button {
        float: none;
    }
}

.infobox {
    display: flex;
    padding: 30px;
    padding-top: 10px;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    border: 1px solid @border-color;
    text-align: center;

    &.infobox-success:before {
        content: '\e904';
        color: @success-color;
    }

    &.infobox-error:before {
        content: '\e903';
        color: @error-color;
    }

    > * {
        width: 60%;
    }

    &:before {
        content: '\e900';
        font-family: icomoon;
        font-size: 130px;
        color: @main-color;
    }

    .infobox-headline {
        font-size: 20px;
    }

    .infobox-message {

        a {
            text-decoration: underline;

            &:hover {
                color: @header-color;
            }
        }

        strong {
            color: @header-color;
        }
    }

    .confirm-button {
        margin-top: 10px;
        width: fit-content;
        color: white;
        text-decoration: none;
        margin: 0 auto;
    }

    ol {
        padding-left: 0;
    }

    ul {

        li {
            justify-content: center;
        }
    }
}

// FAQ
.faq-list {
    clear: both;

    > li {
        border-bottom: 1px solid @border-color;
    }

    span {
        display: block;
        cursor: pointer;
        position: relative;
        color: @header-color;

        &:hover {
            color: @main-color;
        }

        &:before {
            content: '+';
            position: absolute;
            left: 0;
            color: @main-color;
            font-size: 22px;
            font-weight: normal;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    li.show > span:before {
        content: '-';
    }

    > li {

        > span {
            font-size: 20px;
            padding: 10px 0;
            padding-left: 30px;
            font-weight: 400;
        }
    }

    ul {
        display: none;

        span {
            padding-left: 30px;
        }

        li {
            padding: 15px 30px;
            position: relative;
            transition: background-color .2s ease-in-out;

            &.show {
                background-color: @light-bg;

                > span {
                    font-weight: bold;
                }
            }
        }

        article {
            padding-left: 30px;
            display: none;
            font-weight: 400;
            padding-top: 5px;

            p {
                margin: 0;
            }

            a {
                color: @sec-color;
                text-decoration: underline;
                font-weight: 700;

                &:hover {
                    color: @main-color;
                }
            }

            ul {
                display: block;
            }
        }
    }
}

// CMS GALLERY
#gsGalleryContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    clear: both;
    margin-bottom: 20px;
    gap: 5px;

    > a {
        width: ~"calc((100% - 5px) / 2)";
        text-align: center;
        position: relative;

        img {
            display: block;
            margin: 0 auto;
            max-width: 100%;
        }

        span {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            color: white;
            background-color: @main-color;
            padding: 10px;
            font-weight: bold;
        }
    }
}

.article-content {
    margin-bottom: 20px;
}

// CMS BLOG
main {

    &.aside .cms-subarticles-wrapper {
        columns: 2;
    }
}

.cms-subarticles-wrapper {
    columns: 3;
    column-gap: 20px;
    margin-bottom: 20px;
    clear: both;
}

.cms-subarticle {
    outline: 1px solid @border-color;
    break-inside: avoid;
    margin-bottom: 20px;
    padding: 15px 0;
    overflow: hidden;

    > *:not(.photo-wrapper) {
        padding: 0 15px;
    }

    .photo-wrapper {
        display: block;
        margin-top: -15px;
        overflow: hidden;

        &:hover {

            img {
                transform: scale(1.02);
            }
        }

        & + h3 {
            margin-top: 15px;
        }

        img {
            display: block;
            max-width: 100%;
            object-fit: cover;
            width: 100%;
            transition: transform .2s ease-in-out;
        }
    }

    h3 {
        font-size: 20px;
        margin-bottom: 10px;

        &:hover {
            text-decoration: underline;
        }

        a {
            color: @header-color;
        }
    }

    .return-button {
        margin: 5px 0 0 15px;
        border: 0;
        padding: 0;
        text-decoration: underline;
        font-weight: normal;
        font-size: 14px;
    }
}

// USER ORDERS
.user-orders-summary {
    border: 1px solid @border-color;
    margin-bottom: 15px;
    display: flex;

    li {
        color: @header-color;
        padding: 10px;
        width: 50%;
        text-align: center;

        &:first-child {
            border-right: 1px solid @border-color;
        }

        span {
            color: @font-color;
        }

        strong {
            font-size: 20px;
            text-transform: uppercase;
            font-weight: 700;
        }
    }
}

.user-orders-list {
    margin-bottom: 15px;

    > li {
        border: 1px solid @border-color;

        &:not(:first-child) {
            border-top: 0;
        }
    }

    li:nth-child(2n) .order-header {
        background-color: @light-bg;
    }

    .order-header {
        padding: 15px;
        position: relative;
        overflow: hidden;

        h3 {
            float: left;
            color: @header-color;
            font-size: 24px;

            small {
                font-size: 16px;
                font-weight: normal;
            }
        }

        em {
            font-style: normal;
            font-size: 16px;
            font-weight: normal;
        }

        span {
            float: left;
            clear: left;
            position: relative;
            padding-left: 30px;

            b {
                display: inline-block;
                color: @header-color;
                font-weight: 700;
            }

            i {
                width: 22px;
                height: 22px;
                border-radius: 50%;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
            }
        }

        .buttons-wrapper {
            position: absolute;
            top: 10px;
            right: 10px;
            height: ~"calc(100% - 20px)";
            display: flex;
            flex-direction: column;
            gap: 10px;
        }

        a {
            background-color: @main-color;
            color: white;
            flex-grow: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            padding: 0 30px;
            font-weight: 700;

            &:hover {
                background-color: darken(@main-color, 10%);
            }
        }
    }
}

.order-buttons-wrapper {
    padding: 15px;
    padding-top: 10px;

    > strong {
        color: @header-color;
        font-weight: 700;
        margin-bottom: 5px;
        display: block;
    }

    .wrapper {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
    }

    .return-button {
        font-size: 14px;
        font-weight: normal;
        height: 32px;
        padding: 0 12px;
        border-radius: 0;
        border-width: 2px;
    }
}

.order-detailed-info {
    display: flex;
    clear: both;
    flex-flow: row wrap;
    border-top: 1px solid @border-color;

    > div {
        flex: 1;
        border-right: 1px solid @border-color;
        border-bottom: 1px solid @border-color;
        padding: 15px 10px 15px 15px;

        &:nth-child(3),
        &:nth-child(5),
        &:nth-child(6) {
            border-right: none;
        }

        &:nth-child(7) {
            border: none;
        }

        h4 {
            color: @header-color;
            font-size: 16px;
            font-weight: 700;
            margin-bottom: 10px;
        }

        ul {
            list-style: none;

            li {
                padding: 2px 0;
            }
        }
    }

    .item-box-small {
        flex-basis: 33.33%;

        h4 {
            padding-left: 35px;
            position: relative;

            &:before {
                content: '';
                font-family: icomoon;
                color: @main-color;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
                width: 22px;
                text-align: left;
                font-size: 20px;
                font-weight: normal;
            }
        }

        &.user-data h4:before {
            content: '\e811';
            font-size: 26px;
        }

        &.user-address h4:before {
            content: '\e805';
        }

        &.user-history h4:before {
            content: '\e802';
            font-size: 25px;
        }

        &.user-history ul {
            list-style: disc;
            padding-left: 15px;
        }
    }

    .item-box-medium {
        flex-basis: 50%;

        h4 {
            padding-left: 35px;
            position: relative;

            &:before {
                content: '';
                font-family: icomoon;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
                font-size: 22px;
                color: @main-color;
                font-weight: normal;
            }
        }

        p {
            display: block;
            color: @header-color;
            text-transform: none;
            font-weight: normal;
            font-size: var(--fontSize);
            margin-bottom: 10px;
        }

        ul {
            list-style: disc;
            padding-left: 15px;
        }

        ul li {
            padding: 0;
        }

        &.payment h4:before {
            content: '\e815';
            font-size: 24px;
        }

        &.shipment h4:before {
            content: '\e804';
        }
    }

    .item-box-large {
        flex-basis: 100%;

        .confirm-button {
            margin-top: 20px;
        }

        &.product-table {
            padding: 10px;
        }

        table {
            width: 100%;
            border-collapse: collapse;
        }

        thead {
            background-color: lighten(@border-color, 5%);

            small {
                color: @font-color;
                font-size: 11px;
                position: relative;
            }
        }

        td, th {
            border: 1px solid @border-color;
            padding: 10px;

            a {
                color: @font-color;
                text-decoration: underline;
            }
        }

        th {
            color: @header-color;
            font-weight: 700;
            font-size: 16px;
        }

        tbody span {
            display: block;
            font-size: 12px;
            color: rgba(0,0,0,0.4);
        }

        tfoot {
            text-align: right;

            tr:first-child td {
                padding-top: 10px;
            }

            td {
                border: none;
                padding: 2px 10px;

                strong {
                    color: @header-color;
                    font-weight: 700;
                }

                b {
                    color: @header-color;
                    font-size: 20px;
                    font-weight: 700;
                }
            }
        }
    }
}

// SAVED CART
.single-saved-cart {
    position: relative;
    padding: 15px;
    border: 1px solid @border-color;
    border-bottom: none;
    overflow: hidden;

    .buttons-wrapper {
        height: ~"calc(100% - 20px)";
        display: flex;
        gap: 10px;
        clear: both;
        float: left;
        margin-top: 10px;

        a, i {
            background-color: @main-color;
            color: white;
            flex-grow: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            padding: 3px 30px;
            font-weight: 700;
            cursor: pointer;

            &:hover {
                background-color: darken(@main-color, 10%);
            }
        }

        i {
            font-style: normal;
        }

        .delete {
            background-color: @error-color;

            &:hover {
                background-color: darken(@error-color, 10%);
            }
        }
    }

    &:last-child {
        border-bottom: 1px solid @border-color;
        margin-bottom: 30px;
    }

    > span {
        float: left;
        color: @font-color;
        font-weight: normal;

        b {
            color: @header-color;
            font-weight: 700;
        }
    }

    .ul-flex-wrapper {
        display: none;
        width: 100%;
        clear: both;

        > ul {
            display: flex;
            flex-direction: column;

            .summary-position {
                padding-top: 20px;
                min-height: 120px;
                position: relative;
                padding-left: 110px;

                figure {
                    width: 100px;
                    height: 100px;
                    justify-content: flex-start;
                    position: absolute;
                    top: 20px;
                    left: 0;

                    i {
                        color: white;
                        background-color: @error-color;
                        position: absolute;
                        top: 0;
                        left: 0;
                        padding: 0 5px;
                        font-style: normal;
                    }
                }

                > strong {
                    color: @header-color;
                    font-weight: 700;
                    display: block;
                }

                ul {

                    strong {
                        font-weight: 700;
                        color: @header-color;
                    }
                }
            }
        }
    }
}

.shop-buttons-wrapper {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

// THREADS
.thread-header {
    padding: 15px;
    position: relative;
    overflow: hidden;
    border: solid 1px @border-color;

    em {
        font-style: normal;
        font-size: 16px;
        font-weight: normal;
    }

    span {
        float: left;
        clear: left;
        padding-bottom: 5px;

        b {
            display: inline-block;
            color: @header-color;
            font-weight: 700;
        }
    }
}

.thread-messages {
    margin-top: -1px;
    padding: 15px;
    position: relative;
    overflow: hidden;
    border: solid 1px @border-color;
    display: flex;
    flex-direction: column-reverse;

    .message {
        margin-bottom: 30px;

        header {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            gap: 10px;

            i {
                width: 20px;
                height: 20px;
                margin: 0;
                font-style:normal;
                background-image: url("../assets/images/favicon/favicon.png");
                background-size: contain;
            }

            .message-name {
                color: #333;
                font-weight: normal;
                font-size: 16px;
            }

            .message-time {
                font-size: 14px;
            }
        }

        .message-body {
            width: 80%;
            border-radius: 5px;
            padding: 15px;
            background-color: @light-bg;
            position: relative;
            color: #333;
            display: flex;
            flex-wrap: wrap;
            gap: 4px;

            &:after {
                position: absolute;
                top: -12px;
                left: 22px;
                content: " ";
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 12.5px 12px 12.5px;
                border-color: transparent transparent @light-bg transparent;
            }

            a {
                color: @main-color;
                text-decoration: underline;

                &:hover {
                    text-decoration: auto;
                }
            }
        }

        &.client-response {
            
            header {
                flex-direction: row-reverse;

                i {
                    background-image: none;

                    &:before {
                        color: #333;
                        content: "\e811";
                        font-family: icomoon;
                        font-size: 18px;
                        line-height: 18px;
                    }
                } 
            }

            .message-body {
                float: right;
                background-color: #86BB71;
                color: #fff;

                &:after {
                    left: unset;
                    right: 22px;
                    border-color: transparent transparent #86BB71 transparent;
                }

                a {
                    color: white;
                }
            }
        }
    }
}

.thread-response {
    margin-top: -1px;
    padding: 15px;
    position: relative;
    overflow: hidden;
    border: solid 1px @border-color;
}

// USER COMMENTS
.user-comment-header {
    border: 1px solid @border-color;
    padding: 15px;

    .headline {
        margin-bottom: 0;
    }

    h3 {
        color: @header-color;
        font-size: 24px;

        small, em {
            font-weight: normal;
            font-size: 16px;
            font-style: normal;
        }
    }
}

.products-to-comment-wrapper {
    display: flex;
    flex-direction: column;

    .single-comment-row ~ .infobox {
        display: none;
    }

    .infobox {
        margin-top: 30px;
    }
}

.single-comment-row {
    display: flex;
    padding: 30px 0;

    @media (max-width: 1100px) {
        flex-direction: column;
        gap: 20px;
        align-items: center;

        .single-comment-product-info {
            padding: 0;
        }

        form {
            width: 100%;
        }

        .comment-ok-message {
            font-size: 16px;
        }
    }

    &.product-commented {

        form {
            display: none;
        }

        .comment-ok-message {
            display: flex;
        }
    }
    
    &:not(:first-child) {
        border-top: 1px solid @border-color;
    }

    form {
        padding: 0;
        flex-grow: 1;

        &:after {
            display: none;
        }
    }
}

.comment-ok-message {
    display: none;
    align-items: center;
    flex-grow: 1;
    color: @success-color;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 22px;
    letter-spacing: .03em;

    &:before {
        content: '\e801';
        font-family: icomoon;
        font-weight: normal;
        font-size: 40px;
        margin-right: 10px;
    }
}

.single-comment-product-info {
    width: 350px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-right: 30px;

    strong {
        color: @header-color;
        text-align: center;
    }

    img {
        display: block;
        max-width: 100%;
        margin: 0 auto;
    }
}

.stars-rating {
    float: left;
    width: 96px;

    &.average-rating {
        position: relative;
        cursor: pointer;

        > div {
            width: 100%;
            overflow: hidden;

            &:nth-child(1) {

                li:after {
                    content: '\e80f';
                }
            }

            &:nth-child(2) {
                position: absolute;
                top: 0;
                right: 0;
                height: 100%;

                ul {
                    right: 0;
                    position: absolute;
                    top: 0;
                }
            }
        }
    }

    ul {
        float: left;
        display: flex;
        justify-content: space-between;
        width: 96px;
    }

    li {
        color: @main-color;

        &:after {
            content: '\e810';
            font-family: icomoon;
            display: block;
        }

        &.rated {

            &:after {
                content: '\e80f';
            }
        }
    }
}

.comment-input-wrapper {
    position: relative;
    padding-left: 144px;
    overflow: hidden;
    margin-bottom: 22px;

    label {
        position: absolute;
        left: 0;
        top: 0;
        color: @header-color;
    }

    .stars-rating li {
        cursor: pointer;
        transform-origin: center bottom;

        &:hover {
            transform: scale(1.2);
        }
    }
}

// SITEMAP
.sitemap-wrapper {
    display: flex;
    flex-wrap: wrap;
    padding-block: 10px;
    width: 100%;
    gap: 20px;

    ul {
        display: flex;
        flex-direction: column;
        gap: 5px;
        list-style: disc;
        padding-bottom: 5px;
        flex: 1 1 0;
        min-width: 300px;

        &:before {
            content: attr(data-title);
            font-size: calc(var(--fontSize)  + 3px);
            font-weight: bold;
            color: @header-color;
        }

        li a {
            color: @font-color;

            &:hover {
                text-decoration: underline;
                color: @main-color;
            }
        }

        li::marker {
            color: @main-color;
            font-size: 1.1em;
        }

        li {
            margin-left: 15px;
        }
    }

    > ul {
        border: solid 1px @border-color;
        padding: 15px 15px 15px 15px;
        border-radius: var(--borderRadius);
    }
}

// PRIVACY BOX
.privacy-box-trigger {
    display: none;
    position: fixed;
    bottom: 20px;
    z-index: 999;
    opacity: 1;
    color: white;
    width: 50px;
    height: 50px;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-size: 25px;
    .shadow();

    &.right-side-cookies {
        right: 20px;

        &:before {
            right: 25px;
            border-top-left-radius: 100vw;
            border-bottom-left-radius: 100vw;
            padding: 0 40px 0 25px;
            transform-origin: right center;
        }

        & ~ .privacy-box {
            right: 20px;
        }
    }

    &.left-side-cookies {
        left: 20px;

        &:before {
            left: 25px;
            border-top-right-radius: 100vw;
            border-bottom-right-radius: 100vw;
            padding: 0 25px 0 40px;
            transform-origin: left center;
        }

        & ~ .privacy-box {
            left: 20px;
        }
    }

    &:after {
        content: '';
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        background-color: @main-color;
        border-radius: 50%;
        z-index: 1;
    }

    img {
        filter: brightness(0) invert(1);
        position: relative;
        z-index: 2;
        max-height: 70%;
        width: auto;
    }

    &.show {
        display: flex;
    }

    &:before {
        content: 'Ustawienia ciasteczek';
        position: absolute;
        top: 0;
        height: 100%;
        background-color: @header-color;
        color: white;
        font-size: var(--fontSize);
        white-space: nowrap;
        display: flex;
        align-items: center;
        z-index: 0;
        opacity: 0;
        transform: scaleX(0);
        transition: all .3s ease-in-out;
    }

    @media (min-width: 1280px) {
        &:hover {

            &:before {
                transform: scaleX(1);
                opacity: 1;
            }
        }
    }
}

.privacy-box {
    position: fixed;
    bottom: 20px;
    z-index: 10000;
    background-color: white;
    box-shadow: 0 0 20px rgba(0,0,0,.25);
    max-width: min(800px, 50vw);
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;

    &.preferences-visible {

        .privacy-box__consents {
            display: flex;
        }
    }
}

.privacy-box__header {
    display: flex;
    align-items: center;
    gap: 20px;

    img {
        width: auto;
        max-height: 30px;
    }

    strong {
        color: @header-color;
        font-size: 18px;
    }
}

.privacy-box__wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-x: hidden;
    overflow-y: auto;
}

.privacy-box__description {
    color: @header-color;
}

.privacy-box__consents {
    flex-direction: column;
    gap: 10px;
    display: none;
}

.consent-detail {
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: @header-color;
    border-radius: 4px;
    position: relative;
    border: 1px solid @border-color;

    &.toggleable {

        strong {
            cursor: pointer;

            &:hover {
                color: @main-color;
            }

            &:before {
                content: '\e800';
                font-family: icomoon;
                font-weight: normal;
            }
        }
    }

    &.show {

        strong:before {
            transform: scaleY(-1);
        }

        .consent-detail__description {
            display: block;
        }
    }

    strong {
        font-weight: 700;
        display: flex;
        align-items: center;
        gap: 10px;
        flex-grow: 1;
        user-select: none;
        width: 100%;
        color: @header-color;
        padding: 10px;
    }

    span {
        color: @success-color;
        flex-shrink: 0;
        padding: 10px;
        padding-left: 0;
    }
}

.consent-detail__header {
    display: flex;
    align-items: center;
}

.consent-detail__description {
    width: 100%;
    display: none;
    font-size: 13px;
    padding: 10px;
    margin-top: -10px;
}

.consent-detail__checker {
    height: 28px;
    position: relative;
    width: 50px;
    flex-shrink: 0;
    margin-right: 10px;

    &.mandatory {
        display: none;
    }

    input {
        display: none;

        &:checked + div {
            background-color: @success-color;

            &:before {
                transform: translateX(22px);
            }
        }
    }

    div {
        background-color: #ccc;
        bottom: 0;
        cursor: pointer;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        transition: .4s;
        border-radius: 17px;

        &:before {
            content: '';
            background-color: white;
            bottom: 4px;
            height: 20px;
            left: 4px;
            position: absolute;
            transition: .4s;
            width: 20px;
            border-radius: 50%;
        }
    }
}

.privacy-box__actions {
    display: flex;
    gap: 10px;

    button {
        flex: 1 1 0;
        font-size: 14px;
        background-color: @light-bg;
        text-transform: uppercase;
        font-weight: 700;
        color: @font-color;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        min-height: 40px;
        border-radius: 4px;
        padding: 5px;

        &:hover {
            color: @header-color;
        }

        &.privacy-accept-all {
            color: white;
            background-color: @main-color;

            &:hover {
                background: @sec-color;
            }
        }
    }
}

.privacy-box__links {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    a {
        color: @sec-color;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
}

// NEWSLETTER LOGIN
.login-newsletter-row {
    display: flex;
    flex-direction: column;
    gap: 10px;

    label {
        display: flex;
        width: fit-content;
        align-items: center;

        input {
            display: none;

            &:checked + span span {
                background-color: @success-color;

                &:before {
                    transform: translateX(22px);
                }
            }
        }
    }

    label > span {
        height: 28px;
        position: relative;
        width: 50px;
        flex-shrink: 0;
        margin-left: 10px;
        display: block;

        span {
            background-color: #ccc;
            bottom: 0;
            cursor: pointer;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            transition: background-color .4s;
            border-radius: 17px;

            &:before {
                content: '';
                background-color: white;
                bottom: 4px;
                height: 20px;
                left: 4px;
                position: absolute;
                transition: .4s;
                width: 20px;
                border-radius: 50%;
            }
        }
    }
}

// IE GRID FALLBACK
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .products-list,
    .products-list-tabs {
        display: flex;
        flex-wrap: wrap;

        .product {
            min-width: 25%;
            max-width: 30%;
            flex: 1 1 0px;
        }
    }
}

#auto-redirect {
    flex-direction: column;
}